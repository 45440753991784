/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';

// variables
body {
  --light-grey: #e6e6e6;
  --dark-grey: #53535b;
}

$variables: (
  --light-grey: var(--light-grey),
  --dark-grey: var(--dark-grey),
);

@function var($variable) {
  @return map-get($variables, $variable);
}

// styles
* {
  box-sizing: border-box;
}

body,
p,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  outline: none;
}

h1 {
  margin-bottom: 40px;
}

h2 {
  margin: 20px 0;
}

h3 {
  margin: 20px 0;
}

html,
body {
  display: block;
  overflow: auto;
}

body {
  margin: 0;
}

.content-shortcut {
  left: 50%;
  padding: 8px;
  position: absolute;
  transform: translateY(-100%);
  transition: transform 0.3s;
}

.content-shortcut:focus {
  transform: translateY(0%);
}

.content {
  padding: 25px 40px 40px;
  flex-grow: 1;

  &.app-frame {
    padding: 0 !important;
  }

  &.frame {
    display: flex;

    app-profile {
      flex-direction: column;
    }

    app-frame,
    app-profile {
      flex-grow: 1;
      display: flex;
    }
  }
}

iframe.application-frame {
  width: 100%;
  border: none;
}

.card-block {
  padding: 30px 40px !important;
  margin: 0 0 15px;

  &.dx-theme-border-color {
    border: 1px solid;
  }
}

.main-footer {
  padding: 0 40px;
}

.btn-right {
  float: right;
}

.border {
  border: 1px solid;
}

.custom-btn {
  padding: 16px 19px !important;
}

.btn-icon {
  width: 20px !important;
  height: 20px !important;
}

.btn-text-with-icon {
  margin-left: 10px;
}

.dx-scrollview-content {
  display: flex !important;
  flex-direction: column !important;
  min-height: 100% !important;
}

.full-height-scrollable>.dx-scrollable-wrapper>.dx-scrollable-container>.dx-scrollable-content {
  height: 100% !important;
  padding-top: 60px;
}

.dx-theme-generic-typography small {
  font-weight: 500 !important;
}

.dx-accordion-item:first-child {
  border-right: none !important;
  border-left: none !important;
  border-top: none !important;
}

.dx-accordion-item {
  border-right: none !important;
  border-left: none !important;
}

.dx-accordion-item:last-child {
  border-right: none !important;
  border-left: none !important;
  border-bottom: none !important;
}

.dx-datagrid-search-panel {
  min-width: 230px;
}

.page-description-text {
  margin-bottom: 30px;
}

.vertical-alignment {
  vertical-align: middle !important;
}

.horizontal-alignment {
  text-align: center !important;
}

.right-button-group {
  display: flex;
  justify-content: flex-end;

  dx-button {
    margin-left: 10px;
  }
}

.dx-popup-wrapper>.dx-overlay-content {
  border-left: none !important;
  border-right: none !important;
  border-bottom: none !important;
  border-bottom-left-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

ul {
  list-style-type: none;
}

a {
  &:focus {
    outline: 1px solid;
  }

  &:hover {
    text-decoration: none;
  }
}

.dx-popup-title {
  .dx-closebutton {
    &:focus {
      border: 1px solid;
    }
  }
}

.dx-button-has-text .dx-button-content {
  padding: 7px 18px 7px !important;
}

.dx-tabs-scrollable .dx-tabs-wrapper {
  border-color: transparent !important;
}

.dx-toolbar-items-container {
  .dx-dropdownbutton-action.dx-buttongroup-last-item.dx-button .dx-button-content {
    padding: 8px !important;
  }

  .toolbal-btns {
    dx-button {
      margin-left: 5px;
    }
  }

  .dx-button-content {
    i-feather {
      width: 18px !important;
      height: 18px !important;
    }
  }

  .dx-datagrid-search-panel {
    margin: 0;
  }

  .dx-toolbar-after {
    .dx-button-has-text .dx-button-content {
      padding: 8px !important;
    }
  }
}

.action-block {
  display: flex;
  justify-content: flex-end;

  .dx-button {
    margin-left: 10px;
  }
}

.dx-pager .dx-pages .dx-info {
  opacity: 0.9;
}

.menu-item {
  display: flex;
  align-items: center;
  padding: 5px;

  .menu-icon {
    padding-right: 5px;
  }

  .external-link-icon {
    font-size: 0;
    margin-left: 5px;
    height: 18px;
    width: 18px;
  }

  &.last-secondary-menu-item {
    border-bottom: 1px solid;
  }
}

.dx-toolbar-menu-section {
  .dx-item.dx-list-item {
    padding: 5px;

    &:last-of-type {
      .last-secondary-menu-item {
        border-bottom: none;
      }
    }
  }
}

//  ************************* seminarroom *********************** //
.custom-tabs {

  .dx-item-content,
  .dx-tab-content {
    padding: 5px 20px 5px 20px;
  }

  .dx-tabs-expanded {
    width: auto;
    text-align: left;
  }

  .dx-tab-content {
    border-bottom: 0;
  }

  .dx-tabs-wrapper {
    display: flex;
  }

  .dx-tab {
    padding: 0;
    margin-right: 30px;
    background: none;
    border: 0;

    &.dx-state-hover,
    &.dx-tab-selected {
      background: none;

      .dx-tab-content {
        border-bottom: 2px solid;
        border-color: inherit;
      }
    }
  }

  .dx-tab-selected:after,
  .dx-tabs {
    border: 0;
  }

  .dx-fieldset {
    margin: 0;
  }
}

// course item
.all-seminars {
  margin: 15px -15px 0;
  padding-top: 10px;
}

.all-courses-section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.course-item {
  transition: all 400ms;
  margin: 0 15px 15px;
  width: 270px;

  figure {
    position: relative;
    overflow: hidden;
    height: 160px;
    text-align: center;
  }

  .course-background {
    width: 100%;
  }

  .course-favorite {
    display: inline-block;
    position: absolute;
    top: 15px;
    left: 15px;
  }

  .course-type {
    position: absolute;
    bottom: 15px;
    left: 15px;
    width: 30px;
    height: 30px;
    border-radius: 3px;
    overflow: hidden;

    img {
      width: 100%;
    }

    &.centered-course-type {
      width: 60px;
      height: 60px;
      bottom: 50px;
      left: 105px;
    }
  }

  .course-info {
    padding: 20px 10px 15px;
  }

  .course-title {
    height: 42px;
    overflow: hidden;
    margin: 0 0 5px;
    text-align: left;
    white-space: pre-wrap;
  }

  .course-description {
    text-align: left;
    justify-content: space-between;
    display: flex;

    p {
      height: 15px;
      margin-bottom: 5px;
    }

    .course-info-btn {
      align-self: flex-end;
    }
  }
}

.info-icon {
  color: inherit !important;

  i {
    font-size: 24px;
  }
}

.row-position {
  .course-item {
    width: 100%;
    margin: 0 15px 10px;

    .course-info {
      display: flex;
      height: 120px;
      justify-content: space-between;
      padding: 10px 30px;
    }

    .course-title {
      text-align: left;
      align-self: center;
      height: auto;
      width: 100%;
    }

    .course-description {
      width: 100%;
      align-items: center;
    }

    figure {
      float: left;
      width: 200px;
      height: 120px;
    }
  }

  .category-tile {
    position: relative;
    width: 100%;
    max-width: 50%;
    margin-bottom: 20px;
  }

  .course-gallery {
    text-align: center;
    margin-left: -15px;
  }

  .course-type.centered-course-type {
    bottom: 30px;
    left: 70px;
  }
}

.category-item {
  position: relative;
  margin: 0 15px 20px;
}

.category-tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  height: 100%;
  position: absolute;

  figure {
    overflow: hidden;
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
}

.course-gallery {
  padding-bottom: 20px;
  border-bottom: 1px solid;
  margin-left: 280px;

  .dx-gallery-container .dx-item:first-child {
    margin-left: 0 !important;
  }

  .dx-gallery {

    .dx-gallery-nav-button-prev,
    .dx-gallery-nav-button-next {
      &:before {
        overflow: hidden;
        background: rgba(0, 0, 0, 0.2);
        border-radius: 50%;
      }

      &:after {
        display: none;
      }
    }
  }
}

.category-description {
  font-size: 0.9em;
  margin-top: 20px;
}

.last-category .course-gallery {
  border-bottom: 0;
}

.table-actions {
  .dx-dropdownbutton-action.dx-button .dx-button-content {
    padding: 8px;
  }
}

.dx-dropdownbutton-popup-wrapper .dx-list:not(.dx-list-select-decorator-enabled) .dx-list-item-content.table-actions-item {
  padding: 0;

  .dx-button {
    width: 100%;
    text-align: left;
  }
}

.dx-datagrid .dx-row>td {
  padding: 5px !important;
}

.dx-toast-message {
  white-space: pre-wrap;
}

// *********************** mobile *****************************
.mobile-screen,
.dx-device-phone {
  h1 {
    margin-bottom: 20px;
  }

  .main-header {
    left: 0 !important;
  }

  .content {
    padding: 25px 20px 25px;
  }

  .main-footer {
    padding: 0 25px;
  }

  .dx-drawer-content {
    padding-bottom: 60px !important;
  }

  .card-block {
    padding: 15px 20px !important;
  }

  .user-avatar {
    display: block;
  }

  .form-avatar {
    width: 120px;
    height: 120px;
  }

  .user-avatar-upload {
    height: 30%;
    background-size: 25px;
  }

  .personal-data {
    margin-top: 30px !important;
  }

  .footer-navigation {
    margin-bottom: 15px;
  }

  .language-switch i {
    display: none !important;
  }

  .all-courses-section {
    justify-content: center;
  }

  .category-tile {
    position: relative;
    width: 100%;
    margin-bottom: 20px;
  }

  .course-gallery {
    margin-left: 0;
  }
}

@media only screen and (max-width: 365px) {
  .form-avatar {
    width: 160px !important;
    margin-right: 10px !important;
  }
}

@media only screen and (max-width: 600px) {
  html {
    height: 100%;
    margin: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}


.members-popup-view{
  .dx-popup-content{
    padding: 5px 0 5px 5px !important;
    overflow: initial;
  }
}
